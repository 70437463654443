import {axios} from "../../utils/axios";

export function addProvider(data) {
  return axios.post('/provider', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function getProviders() {
  return axios.get('/provider').then(res => res.data);
}
