import { isAuthenticatedAtom } from "../../../logic/atoms";
import { Navigate, Outlet } from "react-router-dom";
import {useAtomValue} from "jotai";

export default function NotAuthRoute() {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);

  if (isAuthenticated) {
    return <Navigate to={"/"} replace={true} />;
  }

  return <Outlet />;
}
