import { Helmet } from "react-helmet";
import {useForm} from "react-hook-form";
import {useAuthHook} from "../../logic/hooks/auth";
import {Logo} from "../../assets/svg";

export default function SignIn() {
  const {
    register,
    handleSubmit,
  } = useForm();
  const { login } = useAuthHook();
  const submit = async (data) => {
    const {id, password, keepLogin} = data;
    try {
      await login({id, password}, keepLogin);
    } catch (err) {
      alert(err.message);
    }
  }

  return (
    <>
      <Helmet>
        <html className="h-full bg-gray-50" />
        <body className="h-full" />
      </Helmet>

      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
          <Logo height={74} className="text-black mx-auto"/>
          <h2 className="mt-8 text-center text-xl tracking-tight text-gray-400">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 shadow-2xl">
            <form className="space-y-6" onSubmit={handleSubmit(submit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  ID
                </label>
                <div className="mt-1">
                  <input
                    id="id"
                    type="text"
                    {...register("id", { required: true })}
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    type="password"
                    {...register("password", { required: true })}
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="keep-login"
                    type="checkbox"
                    {...register("keepLogin")}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="keep-login"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    로그인 유지
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  로그인
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
