import {useEffect, useState} from "react";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import {useSetAtom} from "jotai";
import {pageNameAtom} from "../../logic/atoms";
import {getContents} from "../../logic/api/contents";
import {format} from 'date-fns';
import {useContentHook} from "../../logic/hooks/contents";

const Row: any = SortableElement(({ value, onDelete }: any) => {
  const navigate = useNavigate();

  return (
    <tr key={value.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {value.id}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{value.category}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{value.subCategory}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <button onClick={() => navigate(`${value.id}`)} className='underline underline-offset-4'>
          {value.title}
        </button>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{format(new Date(value.created_at), 'yy.MM.dd HH:mm')}</td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button type='button' className="text-indigo-600 hover:text-indigo-900" onClick={() => onDelete(value)}>삭제</button>
      </td>
    </tr>
  )
});

const SortableTable: any = SortableContainer(({ list, onDelete }) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <colgroup>
        <col width={'50px'}/>
        <col width={'200px'}/>
        <col width={'200px'}/>
        <col width={'auto'}/>
        <col width={'100px'}/>
        <col width={'50px'}/>
      </colgroup>
      <thead className="bg-gray-50">
      <tr>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          ID
        </th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          카테고리
        </th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          서브 카테고리
        </th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          제목
        </th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          작성일
        </th>
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">삭제</span>
        </th>
      </tr>
      </thead>

      <tbody className="divide-y divide-gray-200 bg-white">
      {list.map((value, index) => (
        <Row key={value.id} index={index} value={value} onDelete={onDelete} />
      ))}
      </tbody>
    </table>
  );
});

export default function Contents() {
  const setPageName = useSetAtom(pageNameAtom);
  const navigate = useNavigate();
  const { list: getList, del, sort } = useContentHook();
  const { contents, category } = useLoaderData() as any;
  const [ init, setInit ] = useState(true);
  const [ list, setList ] = useState(contents.list);
  const handleDelete = async (cont) => {
    if (confirm('삭제 하시겠습니까?')) {
      try {
        await del(cont.id);
        setList(list.filter((content: any) => content.id != cont.id));
      } catch(err) {
        alert(err.message)
      }
    }
  };
  const update = async () => {
    try {
      const data = await getList(category);
      setList(data.list);
    } catch(err) {
      alert(err.message);
      setList([]);
    }
  };
  const handleSort = async (prop) => {
    const {oldIndex, newIndex} = prop;
    const item = list[oldIndex];
    if (oldIndex > newIndex) {
      list.splice(oldIndex, 1);
      list.splice(newIndex, 0, item);
    } else {
      list.splice(newIndex, 0, item);
      list.splice(oldIndex, 1);
    }
    try {
      const sortData = list.map((item, index) => ({ id: item.id, order: index+1 }));
      await sort(sortData);
      setList(list);
    } catch(err) {
      alert(err.message);
    }
  }

  setPageName('컨텐츠 목록');

  useEffect(() => {
    if (init) {
      setInit(false);
      return;
    }
    update();
  }, [init, navigate]);

  return (
    <div className="mt-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900"></h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to={`/${category === 'content' ? 'contents' : category}/make`}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            컨텐츠 작성
          </Link>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <SortableTable
                list={list}
                lockAxis='y'
                useDragHandle={false}
                onDelete={handleDelete}
                onSortEnd={handleSort}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Contents.Loader = (category) => async () => {
  try {
    const data = await getContents({ category });
    return { contents: data, category };
  } catch(err) {
    return { contents: [], category };
  }
}
