import { useSetAtom, useAtom } from "jotai";
import { isAuthenticatedAtom, userAtom } from "../atoms";
import {getProfile, postLogin} from "../api/auth";
import { axios } from "../../utils/axios";
import {useQuery, useQueryClient} from "react-query";

export function useAuthHook() {
  const qc = useQueryClient();
  const [isAuthenticated, setAuthenticated] = useAtom(isAuthenticatedAtom);
  const profile = useQuery(['profile'], getProfile, {
    enabled: isAuthenticated,
  });
  const setUser = useSetAtom(userAtom);

  async function login(props: LoginReq, keep) {
    try {
      const res = await postLogin(props);
      const { data } = res;
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
      if (keep) localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      setAuthenticated(true);
      setUser(data.user);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  function logout() {
    setAuthenticated(false);
    qc.setQueryData('profile', null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  return { isAuthenticated, profile, login, logout };
}
