import {addProvider, getProviders} from "../api/provider";

export function useProviderHook() {

  async function add(data) {
    try {
      const res = await addProvider(data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function get() {
    try {
      const res = await getProviders();
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return { add, get };
}