import Axios from 'axios';

const isProd = process.env.NODE_ENV === 'production';

export const axios = Axios.create({
  baseURL: isProd ? 'https://creatorscommunity.club/api' : 'http://localhost:3001/api',
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const {
      config,
      response: { status, data },
    } = error;

    if (status === 401) {
      const res = await data;
      const { error } = res;
      if (error.name === 'TokenExpiredError') {
        const originalRequest = config;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
          const { data } = await axios.get(`/refresh-token`, {
            headers: { 'Authorization': refreshToken }
          });
          const { accessToken } = await data;
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        } catch(err) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          alert('다시 로그인 해주세요');
          location.reload();
        }
      }
    }
    return Promise.reject(error);
  }
);