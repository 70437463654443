import Modal from "../../core/Modal";
import {useForm} from "react-hook-form";
import {useEffect, useRef, useState} from "react";
import {useProviderHook} from "../../../logic/hooks/providers";

export default function ProviderModal({open, onAdd, onClose}) {
  const { register, setValue, watch, reset, handleSubmit } = useForm<any>();
  const cancelButtonRef = useRef(null);
  const [thumbTempUrl, setThumbTempUrl] = useState('');
  const { add, get } = useProviderHook();
  const icon = watch('icon');
  const label = watch('label');

  const handleClose = () => {
    setThumbTempUrl('');
    reset();
    onClose();
  };

  const submit = async (data) => {
    try {
      const { provider } = await add({
        icon: data.icon[0],
        label: data.label
      });
      alert('저장 되었습니다.');
      onAdd(provider);
    } catch(err) {
      alert(err.message);
    }
  }

  useEffect(() => {
    if (!icon) return;
    const file = icon[0];
    if (!file) return;
    const tempUrl = URL.createObjectURL(file);
    setThumbTempUrl(tempUrl);
  }, [icon]);

  return (
    <Modal title="컨텐츠 공급자 추가" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="mt-4 sm:mt-6">
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Icon</label>
            <div className="mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
              <input id="thumb-upload" {...register('icon', {required: true})} type="file" />
              {thumbTempUrl && (
                <div className="mt-3">
                  <img className="rounded-lg object-cover shadow-lg h-40" src={thumbTempUrl} alt="" />
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="label" className="block text-sm font-medium text-gray-700">
              Label
            </label>
            <div className="mt-1">
              <input
                id="label"
                type="text"
                {...register('label')}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="label"
              />
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={!thumbTempUrl || !label}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            저장
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            onClick={handleClose}
            ref={cancelButtonRef}
          >
            취소
          </button>
        </div>
      </form>
    </Modal>
  );
}