import {axios} from "../../utils/axios";

export function saveContent(props) {
  return axios.post('/main-contents/add', props, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function putContent(id, content) {
  return axios.put(`/main-contents/${id}`, content, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function removeContent(id) {
  return axios.delete(`/main-contents/${id}`);
}

export function getMainContent() {
  return axios.get(`/main-contents/list`).then(res => res.data);
}

export function updateContentsSort(data) {
  return axios.put(`/main-contents/sort`, data).then(res => res.data);
}