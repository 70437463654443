import {delContent, getContents, putContent, saveContent, sortContent} from '../api/contents';

export function useContentHook() {
  async function list(category?) {
    try {
      const res = await getContents({ category });
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function save(data) {
    try {
      const res = await saveContent(data);
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function update(id, data) {
    try {
      const res = await putContent(id, data);
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async function sort(sortData) {
    try {
      const res = await sortContent(sortData);
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function del(id) {
    try {
      const res = await delContent(id);
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return { list, save, update, del, sort };
}
