import {axios} from "../../utils/axios";

export function saveContent(props) {
  return axios.post('/contents', props, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function putContent(id, props) {
  return axios.put(`/contents/${id}`, props, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function sortContent(sortData) {
  return axios.put(`/contents/sort`, sortData);
}

export function delContent(id) {
  return axios.delete(`/contents/${id}`);
}

export function getContents({ category }) {
  return axios.get(`/contents?cate=${category}`).then(res => res.data);
}

export function getContentById(id) {
  return axios.get(`/contents/${id}`).then(res => res.data);
}

