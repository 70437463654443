import {useSetAtom} from "jotai";
import {pageNameAtom} from "../../logic/atoms";

export default function About() {
  const setPageName = useSetAtom(pageNameAtom);
  setPageName('어바웃');


  return (
    <div>
      About
    </div>
  )
}