import {useSetAtom} from "jotai";
import {pageNameAtom} from "../../logic/atoms";

export default function Setting() {
  const setPageName = useSetAtom(pageNameAtom);
  setPageName('Setting');

  return (
    <div>

    </div>
  )
}