import { createBrowserRouter } from "react-router-dom";
import AuthRoute from "./components/core/AuthRoute";
import Main from "./pages/Main";
import SignIn from "./pages/SignIn";
import Contents from "./pages/Contents";
import Setting from "./pages/Setting";
import ContentDetail from "./pages/ContentDetail";
import NotAuthRoute from "./components/core/NotAuthRoute";
import ContentMake from "./pages/ContentMake";
import About from "./pages/About";

const authRoutes = [
  {
    path: "",
    index: true,
    element: <Main />,
    loader: Main.Loader,
  },
  {
    path: "about",
    element: <About />,
    loader: Contents.Loader('content'),
  },
  {
    path: "contents",
    element: <Contents />,
    loader: Contents.Loader('content'),
  },
  {
    path: "contents/make",
    element: <ContentMake category={'content'} />,
    loader: ContentMake.Loader,
  },
  {
    path: "contents/:id",
    element: <ContentDetail />,
    loader: ContentDetail.Loader,
  },

  {
    path: "community",
    element: <Contents />,
    loader: Contents.Loader('community'),
  },
  {
    path: "community/make",
    element: <ContentMake category={'community'} />,
    loader: ContentMake.Loader,
  },
  {
    path: "community/:id",
    element: <ContentDetail />,
    loader: ContentDetail.Loader,
  },

  {
    path: "setting",
    element: <Setting />,
  },
];

const notAuthRoutes = [
  {
    path: "/signin",
    index: true,
    element: <SignIn />,
    name: "메인",
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthRoute />,
    children: authRoutes,
  },
  {
    path: "/",
    element: <NotAuthRoute />,
    children: notAuthRoutes,
  },
]);

export default router;
