import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/global.scss';
import router from "./router";
import {RouterProvider} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {axios} from "./utils/axios";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const token = localStorage.getItem('refreshToken');

if (token) {
  axios.defaults.headers.common["Authorization"] = `${token}`;
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);