import {isAuthenticatedAtom, pageNameAtom} from "../../../logic/atoms";
import { Navigate, Outlet} from "react-router-dom";
import Layout from "../../module/Layout";
import {useAtomValue} from "jotai";
import { ScrollRestoration } from "react-router-dom";

export default function AuthRoute() {
  const pageName = useAtomValue(pageNameAtom);
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);

  if (!isAuthenticated) {
    return <Navigate to={"/signin"} replace={true} />;
  }

  return (
    <Layout pageName={pageName}>
      <Outlet />
      <ScrollRestoration />
    </Layout>
  );
}
