import {putContent, removeContent, saveContent, updateContentsSort} from "../api/main";

export function useMainHook() {

  async function addContent(content) {
    try {
      const res = await saveContent(content);
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function updateContent(content) {
    try {
      const res = await putContent(content.id, content);
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function delContent(id) {
    try {
      const res = await removeContent(id);
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function sortContents(data) {
    try {
      const res = await updateContentsSort(data);
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return { addContent, delContent, sortContents, updateContent };
}