import { CKEditor } from '@ckeditor/ckeditor5-react';
import './styles.scss';

interface EditorProps {
  register: any,
  onChange: (val: any) => void,
  defaultValue?: string,
}

const uploadUrl = process.env.NODE_ENV === 'production'
  ? 'https://creatorscommunity.club/api/upload-image'
  : 'http://localhost:3001/api/upload-image'

export default function ContentEditor ({register, onChange, defaultValue}: EditorProps) {
  const { value } = register;

  return (
    <CKEditor
      // @ts-ignore
      editor={ window.BalloonBlockEditor }
      data={defaultValue}
      config={{
        image: {
          type: ['JPEG', 'JPG', 'GIF', 'PNG'],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
          ],
        },
        imagecaption: {},
        link: {
          addTargetToExternalLinks: true,
        },
        mediaEmbed: {
          previewsInData: true,
          providers: [
            {
              name: 'youtube',
              url: [
                /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
                /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
                /^youtube\.com\/embed\/([\w-]+)/,
                /^youtu\.be\/([\w-]+)/,
              ],
              html: (match) => {
                const src = `https://www.youtube.com/embed/${match[1]}`;
                return `<iframe
                    src=${src}
                    width="100%"
                    style='aspect-ratio: 16/9;'
                    frameBorder="0"
                    allowFullScreen
                    title='YouTube Video'
                    class='youtube-frame'
                    allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />`;
              },
            },
          ],
        },
        simpleUpload: { uploadUrl },
      }}
      onChange={(event, editor) => {
        onChange(editor.getData());
      }}
      onBlur={(event, editor) => onChange(editor.getData())}
      // onReady={ editor => {
      //   console.log( 'Editor is ready to use!', editor );
      // } }
      // onBlur={ ( event, editor ) => {
      //   console.log( 'Blur.', editor );
      // } }
      // onFocus={ ( event, editor ) => {
      //   console.log( 'Focus.', editor );
      // } }
    />
  )
}