import {useSetAtom} from "jotai";
import {pageNameAtom} from "../../logic/atoms";
import {useEffect, useState} from "react";
import MainContentModal from "../../components/module/MainContentModal";
import {getMainContent} from "../../logic/api/main";
import {useLoaderData} from "react-router-dom";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {arrayMoveImmutable} from 'array-move';

import {cf} from "../../utils/func";
import { C1, C2, C3, C4, C5, C6, C7, C8, C9, C10 } from "../../assets/svg";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useMainHook} from "../../logic/hooks/main";

const ccc = [C1, C2, C3, C4, C5, C6, C7, C8, C9, C10];

const Item: any = SortableElement(({ item, onDelete, onSelect }) => {
  const CComp = ccc[item.c_index];
  const { delContent } = useMainHook();

  const handleRemove = async () => {
    if (confirm('삭제 하시겠습니까?')) {
      try {
        await delContent(item.id);
        onDelete(item.id);
      } catch(err) {
        alert(err.message);
      }
    }
  };

  const handleSelect = () => {
    onSelect(item.id);
  };

  return (
    <div
      className="relative aspect-[50/100] rounded-lg"
      style={{ height: 'calc(100vh - 320px)'}}
    >
      <button
        type="button"
        className="absolute top-1 right-1 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 z-10"
        onClick={handleRemove}
      >
        <XMarkIcon className="h-10 w-10" aria-hidden="true" color='#fff' />
      </button>
      <div onClick={handleSelect}>
        <div className="absolute inset-0">
          <img src={cf(item._.thumbnail.url)} className="h-full w-full object-cover"/>
        </div>
        <div className="absolute inset-0">
          <CComp className='w-full'/>
        </div>
      </div>
    </div>
  );
});

const List: any = SortableContainer(({ list = [], onDelete, onSelect }) => {
  return (
    <ul className="flex gap-2">
      {list.map((item: any, index) => (
        <Item item={item} key={item.id} index={index} onDelete={onDelete} onSelect={onSelect} />
      ))}
    </ul>
  )
});

export default function Main() {
  const setPageName = useSetAtom(pageNameAtom);
  const [open, setOpen] = useState(false);
  const { contents: initData } = useLoaderData() as any;
  const [currentItem, setCurrentItem] = useState(null);
  const [contents, setContents] = useState<any>(initData.list);
  const [init, setInit] = useState(true);
  const [sorted, setSorted] = useState(1);
  const { sortContents } = useMainHook();

  setPageName('메인 컨텐츠');

  const handleModalOpen = (item = null) => {
    setCurrentItem(item);
    setOpen(true);
  };

  const handleFinish = async () => {
    const data = await getMainContent();
    setContents(data.list);
  };

  const updateSort = () => {
    const data = contents.map((content, index) => ({id: content.id, order: index + 1}));
    sortContents(data);
  };

  const handleSortEnd = async ({oldIndex, newIndex}) => {
    setContents(arrayMoveImmutable(contents, oldIndex, newIndex));
    setSorted(sorted + 1);
  };

  const handleDelete = (id) => {
    const _idx = contents.findIndex((item) => item.id === id);
    const newList = [...contents];
    newList.splice(_idx, 1);
    setContents(newList);
  };

  const handleSelect = (id) => {
    const item = contents.find((item) => item.id === id);
    setCurrentItem(item);
    setOpen(true);
  }

  useEffect(() => {
    if (init) {
      setInit(false);
    } else {
      updateSort();
    }
  }, [sorted]);

  return (
    <div className="mt-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900"></h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => handleModalOpen(null)}
          >
            컨텐츠 추가
          </button>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <List
              list={contents}
              onSortEnd={handleSortEnd}
              axis={'x'}
              pressDelay={200}
              onDelete={handleDelete}
              onSelect={handleSelect}
            />
          </div>
        </div>
      </div>

      <MainContentModal
        open={open}
        item={currentItem}
        onFinish={handleFinish}
        onClose={() => {
          setCurrentItem(null);
          setOpen(false);
        }}
      />
    </div>
  )
}

Main.Loader = async () => {
  try {
    const data = await getMainContent();
    return { contents: data };
  } catch(err) {
    return { contents: [] };
  }
}