import Header from "../Header";

interface LayoutProps extends Comp {
  pageName: string;
}

export default function Layout(props: LayoutProps) {
  const { pageName, children } = props;
  return (
    <>
      <div className="min-h-full">
        <Header />
        <div className="py-10">
          <header>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                {pageName}
              </h1>
            </div>
          </header>

          <main>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
