import {Fragment, useEffect, useRef, useState} from 'react'
import classNames from "classnames";
import {useForm} from "react-hook-form";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import {Dialog, Listbox, Transition} from '@headlessui/react'

import { C1, C2, C3, C4, C5, C6, C7, C8, C9, C10 } from "../../../assets/svg";
import {useContentHook} from "../../../logic/hooks/contents";
import {useMainHook} from "../../../logic/hooks/main";
import {cf} from "../../../utils/func";
import Modal from "../../core/Modal";

const ccc = [C1, C2, C3, C4, C5, C6, C7, C8, C9, C10];

export default function MainContentModal({open, item, onClose, onFinish}) {
  const { register, setValue, watch, reset, handleSubmit } = useForm<any>();
  const { list: getList } = useContentHook();
  const { addContent, updateContent } = useMainHook();

  const cancelButtonRef = useRef(null)
  const [list, setList] = useState<any>(null);
  const [thumbTempUrl, setThumbTempUrl] = useState('');
  const thumbnail = watch('thumbnail');
  const cIndex = watch('cIndex');
  const cUrl = watch('contentUrl');
  const cId = watch('contentId');

  const updateList = async () => {
    const data = await getList('');
    setList(data.list);
  };

  useEffect(() =>{
    if (!cId) {
      setValue('category', '');
      setValue('subCategory', '');
    } else {
      const item = list.find(it => it.id == cId);
      setValue('category', item.category);
      setValue('subCategory', item.subCategory);
    }
  }, [cId]);

  const submit = async (data) => {
    try {
      if (item) {
        await updateContent({
          id: item.id,
          ...data,
          thumbnail: data.thumbnail[0],
        });
      } else {
        await addContent({
          ...data,
          thumbnail: data.thumbnail[0],
        });
      }
      alert('저장 되었습니다.');
      onFinish();
      handleClose();
    } catch(err) {
      alert(err.message);
    }
  }

  const handleClose = () => {
    setThumbTempUrl('');
    reset();
    onClose();
  };

  useEffect(() => {
    if (!thumbnail) return;
    const file = thumbnail[0];
    if (!file) return;
    const tempUrl = URL.createObjectURL(file);
    setThumbTempUrl(tempUrl);
  }, [thumbnail]);

  useEffect(() => {
    if (!item) return;
    setValue('cIndex', item.c_index);
    setValue('contentId', item.content_id);
    setValue('contentUrl', item.content_url);
    setThumbTempUrl(cf(item._.thumbnail.url));
  }, [item]);

  useEffect(() => {
    updateList();
  }, []);

  return (
    <Modal title='컨텐츠 추가' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="mt-4 sm:mt-6">
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Thumbnail</label>
            <div className="mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
              <input id="thumb-upload" {...register('thumbnail', {required: !item})} type="file" />
              {thumbTempUrl && (
                <div className="mt-3">
                  <img className="rounded-lg object-cover shadow-lg h-40" src={thumbTempUrl} alt="" />
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <Listbox value={cIndex} onChange={(value) => setValue('cIndex', value)}>
              {({ open }) => {
                const CurrentC = ccc[cIndex || 0];
                return (
                  <>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span className="block truncate">
                          {cIndex === undefined ? '선택없음' : <CurrentC height={20} />}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {ccc.map((Comp, index) => (
                            <Listbox.Option
                              key={'index_' + index}
                              value={index}
                              className={({ active }) =>
                                classNames(
                                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                            >
                              {({ selected, active }) => (
                                <>
                                  <Comp height={20} />
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )
              }}
            </Listbox>
          </div>
          <div className="mt-4">
            <label htmlFor="content" className="block text-sm font-medium text-gray-700">
              컨텐츠
            </label>
            <select
              id="content"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              {...register('contentId')}
              defaultValue=""
            >
              <option value={''}>{'컨텐츠 선택'}</option>
              {list && list.map(item => (
                <option value={item.id} key={item.id}>{item.title}</option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="url" className="block text-sm font-medium text-gray-700">
              URL
            </label>
            <div className="mt-1">
              <input
                id="url"
                type="text"
                {...register('contentUrl')}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="https://content.url"
              />
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={!thumbTempUrl || (!cIndex && cIndex != 0) || (!cUrl && !cId)}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            저장
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            onClick={handleClose}
            ref={cancelButtonRef}
          >
            취소
          </button>
        </div>
      </form>
    </Modal>
  )
}
