export const Logo = (props) => (
  <svg viewBox="0 0 74 44" {...props}>
    <path d="M71.7922 0H54.1909C52.9771 0 51.9932 0.983944 51.9932 2.19769V6.59967C51.9932 7.81342 52.9771 8.79736 54.1909 8.79736H71.7922C73.0059 8.79736 73.9899 7.81342 73.9899 6.59967V2.19769C73.9899 0.983944 73.0059 0 71.7922 0Z" fill="currentColor"/>
    <path d="M60.7905 33.0049V11.0016C60.7905 9.78789 59.8066 8.80396 58.5928 8.80396H54.1909C52.9771 8.80396 51.9932 9.78789 51.9932 11.0016V33.0049C51.9932 34.2187 52.9771 35.2026 54.1909 35.2026H58.5928C59.8066 35.2026 60.7905 34.2187 60.7905 33.0049Z" fill="currentColor"/>
    <path d="M71.7922 35.2026H54.1909C52.9771 35.2026 51.9932 36.1866 51.9932 37.4003V41.8023C51.9932 43.0161 52.9771 44 54.1909 44H71.7922C73.0059 44 73.9899 43.0161 73.9899 41.8023V37.4003C73.9899 36.1866 73.0059 35.2026 71.7922 35.2026Z" fill="currentColor"/>
    <path d="M71.7922 26.3987H67.3902C66.1764 26.3987 65.1925 27.3826 65.1925 28.5964V32.9983C65.1925 34.2121 66.1764 35.196 67.3902 35.196H71.7922C73.0059 35.196 73.9899 34.2121 73.9899 32.9983V28.5964C73.9899 27.3826 73.0059 26.3987 71.7922 26.3987Z" fill="currentColor"/>
    <path d="M71.7922 8.80396H67.3902C66.1764 8.80396 65.1925 9.78789 65.1925 11.0016V15.4036C65.1925 16.6174 66.1764 17.6013 67.3902 17.6013H71.7922C73.0059 17.6013 73.9899 16.6174 73.9899 15.4036V11.0016C73.9899 9.78789 73.0059 8.80396 71.7922 8.80396Z" fill="currentColor"/>
    <path d="M47.9933 0C35.8433 0 25.9966 9.8533 25.9966 22.0033C25.9966 34.1533 35.8433 44 47.9933 44V37.4003C39.4863 37.4003 32.5962 30.5037 32.5962 22.0033C32.5962 13.4963 39.4929 6.60627 47.9933 6.60627V0Z" fill="currentColor"/>
    <path d="M12.9419 42.5349C16.8094 42.5349 19.9244 39.1756 21.0727 30.8006C21.1123 30.5235 21.3895 30.3519 21.6535 30.4443C21.8515 30.5103 21.9769 30.7149 21.9505 30.9194C20.7494 39.73 17.3175 43.9934 12.6384 43.9934C5.46453 43.9934 0 34.9386 0 22.2871C0 11.0016 4.98275 0 13.4303 0C18.0501 0 21.9967 4.18419 21.9967 10.7047C21.9967 13.9187 20.5382 16.7368 18.5913 16.7368C16.8292 16.7368 15.5554 14.8889 15.5554 12.0642C15.5554 10.0183 16.222 8.55977 17.1327 7.58962C17.9841 6.61947 18.4725 6.13109 18.4725 5.15434C18.4725 3.01605 16.222 1.26054 13.6745 1.26054C6.92965 1.26054 6.26308 13.5227 6.26308 22.1881C6.26308 34.7472 8.14399 42.5349 12.9485 42.5349H12.9419Z" fill="currentColor"/>
  </svg>
);

export const C1 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M510 153V0C228.33 0 0 228.34 0 510C0 791.67 228.33 1020 510 1020V867C312.84 867 153 707.17 153 510C153 312.83 312.83 153 510 153Z" fill="currentColor"/>
  </svg>
);

export const C2 = (props) => (
  <svg viewBox="0 0 510 1020" {...props} >
      <polygon
        fill="currentColor"
        points="459,102.2 459,51.2 408,51.2 357,51.2 357,0.2 306,0.2 255,0.2 204,0.2 153,0.2 153,51.2 102,51.2 51,51.2
        51,102.2 0,102.2 0,153.2 0,204.2 0,255.2 0,306.2 0,357.2 0,408.2 0,459.2 0,510.2 0,561.2 0,612.2 0,663.2 0,714.2 0,765.2
        0,816.2 0,867.2 0,918.2 51,918.2 51,969.2 102,969.2 153,969.2 153,1020.2 204,1020.2 255,1020.2 306,1020.2 357,1020.2 357,969.2
        408,969.2 459,969.2 459,918.2 510,918.2 510,867.2 510,816.2 510,765.2 510,714.2 510,663.2 459,663.2 408,663.2 408,714.2
        408,765.2 408,816.2 408,867.2 357,867.2 357,918.2 306,918.2 255,918.2 204,918.2 153,918.2 153,867.2 102,867.2 102,816.2
        102,765.2 102,714.2 102,663.2 102,612.2 102,561.2 102,510.2 102,459.2 102,408.2 102,357.2 102,306.2 102,255.2 102,204.2
        102,153.2 153,153.2 153,102.2 204,102.2 255,102.2 306,102.2 357,102.2 357,153.2 408,153.2 408,204.2 408,255.2 408,306.2
        408,357.2 459,357.2 510,357.2 510,306.2 510,255.2 510,204.2 510,153.2 510,102.2 "
      />
  </svg>
);

export const C3 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M510 153V0H0V1020H510V867H153V153H510Z" fill="currentColor"/>
  </svg>
);

export const C4 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M300.08 986.15C389.77 986.15 461.89 908.22 488.65 714.19C489.53 707.8 495.98 703.77 502.08 705.86C506.68 707.44 509.56 712.08 508.9 716.91C481.09 921.14 401.47 1020 293.04 1020C126.79 1020 0 810.13 0 516.77C0 255 115.52 0 311.35 0C418.43 0 510 97.03 510 248.23C510 322.7 476.19 388.14 431.1 388.14C390.24 388.14 360.66 345.26 360.66 279.82C360.66 232.43 376.16 198.58 397.29 176.02C417.01 153.45 428.29 142.17 428.29 119.6C428.29 69.95 376.16 29.33 316.99 29.33C160.61 29.33 145.11 313.67 145.11 514.51C145.11 805.62 188.79 986.15 300.08 986.15Z" fill="currentColor"/>
  </svg>
);

export const C5 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M459 51V102H102V918H459V969H51V51H459ZM510 0H0V1020H510V867H153V153H510V0Z" fill="currentColor"/>
  </svg>
);

export const C6 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M408 0H102C45.667 0 0 45.667 0 102C0 158.333 45.667 204 102 204H408C464.333 204 510 158.333 510 102C510 45.667 464.333 0 408 0Z" fill="currentColor"/>
      <path d="M204 714V306C204 249.667 158.333 204 102 204C45.667 204 0 249.667 0 306V714C0 770.333 45.667 816 102 816C158.333 816 204 770.333 204 714Z" fill="currentColor"/>
      <path d="M408 816H102C45.667 816 0 861.667 0 918C0 974.333 45.667 1020 102 1020H408C464.333 1020 510 974.333 510 918C510 861.667 464.333 816 408 816Z" fill="currentColor"/>
      <path d="M510 714C510 657.667 464.333 612 408 612C351.667 612 306 657.667 306 714C306 770.333 351.667 816 408 816C464.333 816 510 770.333 510 714Z" fill="currentColor"/>
      <path d="M510 306C510 249.667 464.333 204 408 204C351.667 204 306 249.667 306 306C306 362.333 351.667 408 408 408C464.333 408 510 362.333 510 306Z" fill="currentColor"/>
  </svg>
);

export const C7 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M479.4 663C448.61 858.46 358.96 999.6 255 999.6C125.43 999.6 30.6 780.4 30.6 510C30.6 239.6 125.43 20.4 255 20.4C358.96 20.4 448.61 161.54 479.4 357H510V0H0V1020H510V663H479.4Z" fill="currentColor"/>
  </svg>
);

export const C8 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M357 663V765C357 821.24 311.24 867 255 867C198.76 867 153 821.24 153 765V255C153 198.76 198.76 153 255 153C311.24 153 357 198.76 357 255V357H510V255C510 114.17 395.83 0 255 0C114.17 0 0 114.17 0 255V765C0 905.83 114.17 1020 255 1020C395.83 1020 510 905.83 510 765V663H357Z" fill="currentColor"/>
  </svg>
);

export const C9 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M288.1 21.46C362.47 21.46 424.03 88.7 440.27 198.85C453.09 287.55 468.48 363.37 495.84 363.37H499.26C505.19 363.37 510 358.56 510 352.63V22.19C510 16.26 505.19 11.45 499.26 11.45H496.69C469.33 11.45 476.18 85.84 447.11 85.84C418.04 85.84 379.57 0 286.39 0C128.23 0 0 210.3 0 509.29C0 808.28 126.52 1020 287.24 1020C447.96 1020 499.57 831.87 509.98 689.42C510.22 686.18 508.02 683.29 504.85 682.58C500.98 681.72 497.27 684.48 496.93 688.42C482.09 859.5 420.43 997.11 290.66 997.11C180.38 997.11 122.25 831.16 122.25 509.28C122.25 187.4 181.24 21.45 288.1 21.45V21.46Z" fill="currentColor"/>
  </svg>
);

export const C10 = (props) => (
  <svg viewBox="0 0 510 1020" {...props}>
      <path d="M510 153V0H0V1020H510V867C312.84 867 153 707.17 153 510C153 312.83 312.83 153 510 153Z" fill="currentColor"/>
  </svg>
);
