import {Helmet} from "react-helmet";
import {useSetAtom} from "jotai";
import {pageNameAtom} from "../../logic/atoms";
import {useForm} from "react-hook-form";
import ContentEditor from "../../components/core/Editor";
import {useContentHook} from "../../logic/hooks/contents";
import {Fragment, useEffect, useState} from "react";
import ProviderModal from "../../components/module/ProviderModal";
import {getProviders} from "../../logic/api/provider";
import {useLoaderData} from "react-router-dom";
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import classNames from "classnames";
import {cf} from "../../utils/func";

const mainCate = [
  {key: 'content', label: 'Contents'},
  {key: 'community', label: 'Commnunity'},
];

const subCate = {
  content: [
    {key: 'news', label: 'News'},
    {key: 'trend', label: 'Trend'},
    {key: 'creators', label: 'Creators'},
    {key: 'tips', label: 'Tips'},
  ],
  community: [
    {key: 'seminar', label: 'Seminar'},
    {key: 'class', label: 'Class'},
    {key: 'meetup', label: 'Meet Up'},
    {key: 'party', label: 'Party'},
  ],
};

const publishOptions = [
  {
    label: '공개',
    value: 'on',
  },
  {
    label: '비공개',
    value: 'off',
  },
];

export default function ContentMake({ category: pageCategory }: any) {
  const {register, setValue, watch, handleSubmit} = useForm<any>({
    defaultValues: {
      category: pageCategory,
      subCate: subCate[pageCategory][0].key,
    }
  });

  const [thumbTempUrl, setThumbTempUrl] = useState('');
  const [thumbMoTempUrl, setThumbMoTempUrl] = useState('');
  const [coverTempUrl, setCoverTempUrl] = useState('');
  const [ currentProvider, setCurrentProvider ] = useState<any>(null);
  const { providers } = useLoaderData() as any;
  const [modalOpen, setModalOpen] = useState(false);
  const setPageName = useSetAtom(pageNameAtom);
  const {save} = useContentHook()
  const category = watch('category');
  const outUrl = watch('outUrl');
  const provider = watch('provIconId');
  const thumbnail = watch('thumbnail');
  const mobileImage = watch('mobileImage');
  const coverImage = watch('coverImage');

  setPageName('컨텐츠 작성');

  const submit = async (data) => {
    try {
      await save({
        publish: data.publish === 'on',
        category: data.category,
        subCategory: data.subCategory,
        title: data.title,
        subTitle: data.subTitle || null,
        credit: data.credit,
        prov_icon_id: data.provIconId,
        outUrl: data.outUrl,
        body: data.body,
        tips: data.tips,
        thumbnail: data.thumbnail[0],
        coverImage: data.coverImage[0],
        mobileImage: data.mobileImage[0],
      });
      alert('저장 되었습니다.');
      history.back();
    } catch (err) {
      alert(err.message)
    }
  };

  const handleAddProvider = (item) => {
    setCurrentProvider(item);
    setValue('provIconId', item.icon_id);
    setModalOpen(false);
  };

  const handleChangeProvider = (prov) => {
    if (!prov) {
      setValue('provIconId', null);
      setCurrentProvider(null);
    } else if (prov === 'add') {
      setModalOpen(true);
      setValue('provIconId', null);
      setCurrentProvider(null);
    } else {
      setValue('provIconId', prov.icon_id);
      setCurrentProvider(prov);
    }
  };

  useEffect(() => {
    if (!thumbnail) return;
    const file = thumbnail[0];
    if (!file) return;
    const tempUrl = URL.createObjectURL(file);
    setThumbTempUrl(tempUrl);
  }, [thumbnail])

  useEffect(() => {
    if (!coverImage) return;
    const file = coverImage[0];
    if (!file) return;
    const tempUrl = URL.createObjectURL(file);
    setCoverTempUrl(tempUrl);
  }, [coverImage])

  useEffect(() => {
    if (!mobileImage) return;
    const file = mobileImage[0];
    if (!file) return;
    const tempUrl = URL.createObjectURL(file);
    setThumbMoTempUrl(tempUrl);
  }, [mobileImage])

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <Helmet>
          <body className="bg-gray-100"/>
        </Helmet>

        <div className="mt-12">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Meta</h3>
                <p className="mt-1 text-sm text-gray-600">
                  컨텐츠 기본 정보
                </p>
              </div>
            </div>

            <div className="mt-5 md:col-span-2 md:mt-0">
              <div>
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                      <div>
                        <label className="text-base font-medium text-gray-900">공개 설정</label>
                        <fieldset className="mt-4">
                          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            {publishOptions.map((publ) => (
                              <div key={publ.label} className="flex items-center">
                                <input
                                  id={publ.label}
                                  type="radio"
                                  defaultChecked={publ.value === 'off'}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  {...register('publish', { required: !!outUrl })}
                                  value={publ.value}
                                />
                                <label htmlFor={publ.label} className="ml-3 block text-sm font-medium text-gray-700">
                                  {publ.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>

                      <div>
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                          메인 카테고리
                        </label>
                        <select
                          id="category"
                          disabled={true}
                          {...register('category', {required: true})}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          {mainCate.map(cate => (
                            <option key={cate.key} value={cate.key}>{cate.label}</option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label htmlFor="subCategory" className="block text-sm font-medium text-gray-700">
                          서브 카테고리
                        </label>
                        <select
                          id="country"
                          {...register('subCategory')}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          {subCate[category || 'content']?.map(cate => (
                            <option key={cate.key} value={cate.key}>{cate.label}</option>
                          ))}
                        </select>
                      </div>

                      <div className="col-span-3">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                          제목
                        </label>
                        <textarea
                          id="title"
                          {...register('title', {required: true})}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      {category === 'content' && (
                        <div className="col-span-3">
                          <label htmlFor="subTitle" className="block text-sm font-medium text-gray-700">
                            서브 타이틀
                          </label>

                          <input
                            id="subTitle"
                            type="text"
                            {...register('subTitle', {required: category === 'content'})}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      )}

                      {category === 'content' && (
                        <div className="col-span-3">
                          <div className='grid grid-cols-6 gap-2 items-end'>
                            <div className=''>
                              <Listbox value={currentProvider} onChange={handleChangeProvider}>
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className="block text-sm font-medium text-gray-700">OUT URL</Listbox.Label>
                                    <div className="relative mt-1">
                                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="flex items-center">
                                          {currentProvider && currentProvider !== 'add' ? (
                                            <img src={cf(currentProvider?._.icon.url)} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                                          ) : '공급자 선택'}
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          <Listbox.Option
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-6'
                                              )
                                            }
                                            value={''}
                                          >
                                            <div className="flex items-center">선택안함</div>
                                          </Listbox.Option>
                                          {providers.map((prov) => (
                                            <Listbox.Option
                                              key={prov.id}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                  'relative cursor-default select-none py-2 pl-3 pr-6'
                                                )
                                              }
                                              value={prov}
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <div className="flex items-center">
                                                    <img src={cf(prov._.icon.url)} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                                    <span className={'text-xs ml-2 whitespace-nowrap text-ellipsis overflow-hidden'}>{prov.label}</span>
                                                  </div>
                                                  {selected ? (
                                                    <span className={classNames(active ? 'text-white' : 'text-indigo-600','absolute inset-y-0 right-0 flex items-center pr-1')}>
                                                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ))}
                                          <Listbox.Option
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-6'
                                              )
                                            }
                                            value={'add'}
                                          >
                                            {({ selected, active }) => (
                                              <>
                                                <div className="flex items-center">추가</div>
                                                {provider === 'add' ? (
                                                  <span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-1')}>
                                                    <CheckIcon className="h-4 w-4" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                            <div className='col-span-5'>
                              <input
                                id="outUrl"
                                type="text"
                                {...register('outUrl')}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-span-3">
                        <label htmlFor="credit" className="block text-sm font-medium text-gray-700">
                          크레딧
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="credit"
                            rows={3}
                            {...register('credit')}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="you@example.com"
                            defaultValue={''}
                          />
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">Thumbnail</label>
                        <div
                          className="mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                          <input id="thumb-upload" {...register('thumbnail', {required: true})} type="file"/>
                          {thumbTempUrl && (
                            <div className="mt-3">
                              <img className="rounded-lg object-cover shadow-lg" src={thumbTempUrl} alt=""/>
                            </div>
                          )}
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">Mobile Image</label>
                        <div
                          className="mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                          <input id="mobile-upload" {...register('mobileImage', {required: true})} type="file"/>
                          {thumbMoTempUrl && (
                            <div className="mt-3">
                              <img className="rounded-lg object-cover shadow-lg" src={thumbMoTempUrl} alt=""/>
                            </div>
                          )}
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">Cover Image</label>
                        <div
                          className="mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                          <input id="cover-upload" {...register('coverImage', {required: true})} type="file"/>
                          {coverTempUrl && (
                            <div className="mt-3">
                              <img className="rounded-lg object-cover shadow-lg" src={coverTempUrl} alt=""/>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"/>
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Main Content</h3>
                <p className="mt-1 text-sm text-gray-600">메인 콘텐츠 내용</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <div
                          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                          <ContentEditor register={register('body')} onChange={(val) => setValue('body', val)}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"/>
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Tips (Optional)</h3>
                <p className="mt-1 text-sm text-gray-600">팁</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <div
                          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                          <ContentEditor register={register('tips')} onChange={(val) => setValue('tips', val)}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 py-3 text-right mt-12">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            저장
          </button>
        </div>
      </form>

      <ProviderModal
        open={modalOpen}
        onAdd={handleAddProvider}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}

ContentMake.Loader = async () => {
  try {
    const data = await getProviders();
    return { providers: data };
  } catch(err) {
    return { providers: [] };
  }
}